import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {DevVersionService} from '../dev-versions/dev-version.service';

@Injectable()
export class RegionGuard implements CanActivate {

  constructor(private devVersionService: DevVersionService) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.devVersionService.resolveRegion(state.url, state);
  }
}
