import {MemoizedSelector} from '@ngrx/store/src/selector';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppContainer} from './dev-version.reducer';
import {DevVersionData} from '../../core/dev-versions/dev-version-data';

export const featureSelector: MemoizedSelector<object, AppContainer> = createFeatureSelector<AppContainer>('applicationState');
export const getDevVersionData: MemoizedSelector<object, DevVersionData> = createSelector(featureSelector, state => state.devVersionData);
export const getBeDevVersion: MemoizedSelector<object, string> = createSelector(featureSelector, state => state.beDevVersion);
export const getRegion: MemoizedSelector<object, string> = createSelector(featureSelector, state => state.region);
export const getFeDevVersion: MemoizedSelector<object, string> = createSelector(featureSelector, state => state.feDevVersion);
export const getUseDevEnvironment: MemoizedSelector<object, boolean> = createSelector(featureSelector, state => state.useDevEnvironment);
